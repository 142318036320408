<template>
  <div>
    <v-dialog v-model="ModalAdd" persistent max-width="50%">
      <v-card>
        <v-card-title> Tambah Jabatan </v-card-title>

        <v-divider class="mt-2 mb-5"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="bkn_id"
                  label="ID JABATAN BKN"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="8">
                <v-select
                  v-model="formasi_id"
                  label="Formasi Jabatan"
                  :items="refformasi"
                  item-value="id"
                  item-text="nama"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-select
                  v-model="shift"
                  label="Shift Kerja"
                  :items="refshift"
                  item-value="id"
                  item-text="nama"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="nama_jabatan"
                  label="Nama Jabatan"
                  outlined
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  v-model="alias_jabatan"
                  label="Nama Alias"
                  outlined
                  dense
                  rows="2"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="bup"
                  label="Batas Usia Pensiun"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider class="mt-n5"></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="gray" @click="closeModal()"> Batal </v-btn>

          <v-btn color="primary" @click="add()" v-if="LBtn"> Simpan </v-btn>
          <v-btn color="primary" loading v-else> Simpan </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";
import getRef from "@/helper/getReferensi.js";

import SView from "@/store/jabatan/fungsional/view";
import SAdd from "@/store/jabatan/fungsional/add";

export default {
  computed: {
    ModalAdd: {
      get() {
        return SAdd.state.ModalAdd;
      },
      set(value) {
        SAdd.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SAdd.state.data;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async ModalAdd(value) {
      if (value) {
        this.refformasi = await getRef.formasi(this.token);
        this.refshift = await getRef.shift();
      }
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    LBtn: true,

    refformasi: [],
    refshift: [],

    bkn_id: "",
    formasi_id: "",
    shift: "",
    nama_jabatan: "",
    alias_jabatan: "",
    bup: "",
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async add() {
      this.LBtn = false;

      let data = {
        idBKN: this.bkn_id,
        idFormasi: this.formasi_id,
        shift: this.shift,
        nama: this.nama_jabatan,
        alias: this.alias_jabatan,
        bup: this.bup,
      };

      const url = process.env.VUE_APP_API + "ref/fungsional";
      this.axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            this.LBtn = true;
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", error.response.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", error.response.data.success);
            console.log(error.response.status);
            console.log("Error", error.message);
            this.LBtn = true;
          }

          this.closeModal();
        });
    },

    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.ModalAdd = false;
    },
  },
};
</script>
